import API from "../services/services";

class OutgoingGoodBloodBagServices {
  getAll(params) {
    return API.get("outgoing_goods_blood_bag/list", { params });
  }

  getDetail(id) {
    return API.get("outgoing_goods_blood_bag/detail/" + id);
  }

  add(payload) {
    return API.post("outgoing_goods_blood_bag/list/store", payload);
  }

  update(payload) {
    return API.post("outgoing_goods_blood_bag/list/update", payload);
  }

  delete(id) {
    return API.post("outgoing_goods_blood_bag/list/delete", { id });
  }

  // usage : direct to component without vuex
  checkingBarcode(payload) {
    return API.post('outgoing_goods_blood_bag/list/cheking_barcode', payload)
  }
}

export default new OutgoingGoodBloodBagServices();
