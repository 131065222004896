<template>
  <div>
    <h2 class="mb-5">Detail Aktivasi Pendonor</h2>
    <b-progress
      :value="progress == 0 ? progress : progress * 2"
      :max="6"
      variant="danger"
      height="3px"
      class="mx-auto"
      style="width: 75%"
    ></b-progress>

    <div class="d-flex justify-content-center" style="margin-top: -1.1rem">
      <div class="d-flex step-row">
        <div v-for="(step, index) in steps" :key="index" class="step-col">
          <div v-html="getSvgIcon(index)"></div>
          <p class="mt-3">{{ step }}</p>
          <b-badge :variant="getBadgeVariant(index)">{{
            getBadgeText(index)
          }}</b-badge>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BProgress, BBadge } from "bootstrap-vue";

export default {
  name: "ProgressBarStatus",
  components: { BProgress, BBadge },
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    status: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      steps: ["Registrasi", "Dokter", "HB", "Aftaf"],
    };
  },
  methods: {
    getSvgIcon(index) {
      const progress = Number(this.progress);
      const maxProgress = this.steps.length;

      if (index < progress) {
        return `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="16" cy="16" r="15" stroke="#FF0022" stroke-width="2"/><circle cx="16" cy="16" r="5" fill="#FF0022"/></svg>`;
      } else if (index === progress && progress !== maxProgress) {
        return `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="16" cy="16" r="15" stroke="#FF0022" stroke-width="2"/><circle cx="16" cy="16" r="5" fill="#FF0022"/></svg>`;
      } else {
        return `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="16" cy="16" r="15" stroke="#9CA3AF" stroke-width="2"/><circle cx="16" cy="16" r="5" fill="#D1D5DB"/></svg>`;
      }
    },
    getHrClass(index) {
      const progress = Number(this.progress);
      return index < progress ? "bg-danger" : "bg-secondary-1";
    },
    getBadgeVariant(index) {
      const progress = Number(this.progress);
      if (index < progress) {
        return "success";
      } else if (index === progress) {
        if (this.status === "Approved" || this.status === "Done") {
          return "success";
        } else if (this.status === "Rejected") {
          return "danger";
        } else {
          return "warning";
        }
      } else if (index === progress) {
      } else {
        return "secondary-1";
      }
    },
    getBadgeText(index) {
      const progress = Number(this.progress);
      const maxProgress = this.steps.length;
      if (index < progress) {
        return "Terima";
      } else if (index === progress && progress !== maxProgress) {
        if (
          this.status === "Approved" ||
          this.status === "Done" ||
          this.status === "Active"
        ) {
          return "Terima";
        } else if (this.status === "Rejected" || this.status === "Non-Active") {
          return "DiTolak";
        } else {
          return "Proses";
        }
      } else {
        return "Tertunda";
      }
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}

svg {
  margin-bottom: 1rem;
}

.step-row {
  width: 100%;
}

.step-col {
  flex: 1;
  text-align: center;
}

.step-hr {
  width: 50%;
  height: 2px;
  margin: 0.5rem 0;
}

.bg-danger {
  background-color: #ff0022 !important;
}

.bg-secondary {
  background-color: #9ca3af !important;
}
</style>
