<template>
  <div>
    <b-card>
      <progress-bar-status :progress="3" />
    </b-card>
    <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0 d-flex column mt-3">
      <b-col
        cols="12"
        class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden mb-10"
      >
        <h2>Diisi oleh Aftaf</h2>
        <h4>
          Golongan Darah:
          <strong> {{ formatBloodType(hb_examination.blood_type) }}</strong>
        </h4>

        <b-form class="form">
          <b-row>
            <b-col cols="4"> No. Kantong <span class="required"></span></b-col>
            <b-col cols="8">
              <b-form-group>
                <b-form-input
                  id="kantong"
                  v-model="formData.noKantong"
                  :disabled="isLoading"
                  :state="combineValidationStates"
                  @input="onInput"
                  @blur="checkBarcode"
                />
                <b-form-invalid-feedback v-if="showVuelidateError">
                  {{ v$.formData.noKantong.$errors[0].$message }}
                </b-form-invalid-feedback>
                <b-form-invalid-feedback v-else-if="inputState === false">
                  {{ errorMessage }}
                </b-form-invalid-feedback>
                <b-form-valid-feedback v-if="inputState === true">
                  Barcode Siap Digunakan
                </b-form-valid-feedback>
                <b-spinner v-if="isLoading" small label="Checking barcode..." />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="4">
              <label for="validasiKantongDarah"
                >Validasi Kantong Darah <span class="required"></span
              ></label>
            </b-col>
            <b-col cols="8">
              <b-form-radio-group
                v-model="formData.validasiKantongDarah"
                class="d-flex"
                :state="
                  v$.formData.validasiKantongDarah.$dirty
                    ? !v$.formData.validasiKantongDarah.$error
                    : null
                "
              >
                <b-form-radio value="1"> Valid </b-form-radio>
                <b-form-radio class="ml-3" value="0">
                  Tidak Valid
                </b-form-radio>
              </b-form-radio-group>
              <b-form-invalid-feedback
                :state="
                  v$.formData.validasiKantongDarah.$dirty
                    ? !v$.formData.validasiKantongDarah.$error
                    : null
                "
                class="text-danger"
              >
                Data harus diisi
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="4">
              <label for="cuciLenganLapTisu"
                >Cuci Lengan / Lap Tisu <br />Antiseptic<span
                  class="required"
                ></span
              ></label>
            </b-col>
            <b-col cols="4">
              <label for="jamPenusukan"
                >Jam Penusukan <span class="required"></span
              ></label>
            </b-col>
            <b-col cols="8" class="d-flex" style="gap: 1rem">
              <div>
                <b-form-input
                  type="time"
                  v-model="formData.jamMulaiPenusukan"
                  :state="
                    v$.formData.jamMulaiPenusukan.$dirty
                      ? !v$.formData.jamMulaiPenusukan.$error
                      : null
                  "
                  placeholder="Mulai"
                  :value="
                    new Date().toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                  "
                />
                <b-form-invalid-feedback
                  v-if="
                    v$.formData.jamMulaiPenusukan.$dirty &&
                    v$.formData.jamMulaiPenusukan.$error
                  "
                >
                  Data harus diisi
                </b-form-invalid-feedback>
              </div>
              <span class="mt-2">s/d</span>
              <div>
                <b-form-input
                  type="time"
                  v-model="formData.jamSelesaiPenusukan"
                  placeholder="Selesai"
                  :state="
                    v$.formData.jamSelesaiPenusukan.$dirty
                      ? !v$.formData.jamSelesaiPenusukan.$error
                      : null
                  "
                />
                <b-form-invalid-feedback
                  v-if="
                    v$.formData.jamSelesaiPenusukan.$dirty &&
                    v$.formData.jamSelesaiPenusukan.$error
                  "
                >
                  Data harus diisi
                </b-form-invalid-feedback>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="4">
              <label for="cuciLenganLapTisu"
                >Cuci Lengan / Lap Tisu <br />Antiseptic<span
                  class="required"
                ></span
              ></label>
            </b-col>
            <b-col cols="8" class="d-flex">
              <b-form-radio-group
                v-model="formData.antiseptic"
                class="d-flex"
                :state="
                  v$.formData.antiseptic.$dirty
                    ? !v$.formData.antiseptic.$error
                    : null
                "
                @blur="v$.formData.antiseptic.$touch()"
              >
                <b-form-radio value="1"> Sudah </b-form-radio>
                <b-form-radio class="ml-3" value="0"> Belum </b-form-radio>
              </b-form-radio-group>
              <b-form-invalid-feedback
                :state="
                  v$.formData.antiseptic.$dirty
                    ? !v$.formData.antiseptic.$error
                    : null
                "
              >
                Data harus diisi
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <h3 class="mt-5 mb-3">Reaksi Pengambilan</h3>
          <b-row class="reaksi d-flex align-items-center">
            <b-col cols="4">
              <label>Reaksi Pengambilan <span class="required"></span></label>
            </b-col>
            <b-col cols="8">
              <b-form-radio-group
                v-model="formData.reaksiPengambilan"
                class="d-flex align-items-center"
                :state="
                  v$.formData.reaksiPengambilan.$dirty
                    ? !v$.formData.reaksiPengambilan.$error
                    : null
                "
                @blur="v$.formData.reaksiPengambilan.$touch()"
              >
                <b-form-radio value="lancar"> Lancar </b-form-radio>
                <b-form-radio class="mx-3" value="tidak lancar"
                  >Tidak Lancar</b-form-radio
                >
                <b-form-radio value="stop"> Stop </b-form-radio>
                <b-form-input
                  v-model="formData.reaksiPengambilanCC"
                  style="width: 4rem; margin-left: 5px"
                  type="number"
                  :disabled="formData.reaksiPengambilan !== 'stop'"
                  placeholder="cc"
                />
              </b-form-radio-group>
              <b-form-invalid-feedback
                :state="
                  v$.formData.reaksiPengambilan.$dirty
                    ? !v$.formData.reaksiPengambilan.$error
                    : null
                "
              >
                Data harus diisi
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col cols="4">
              <label>Reaksi Samping <span class="required"></span></label>
            </b-col>
            <b-col cols="8">
              <b-form-radio-group
                v-model="formData.reaksiSamping"
                :state="
                  v$.formData.reaksiSamping.$dirty
                    ? !v$.formData.reaksiSamping.$error
                    : null
                "
                @blur="v$.formData.reaksiSamping.$touch()"
              >
                <div class="d-flex">
                  <b-form-radio value="tidak ada"> Tidak Ada </b-form-radio>
                  <b-form-radio value="hematoma"> Hematoma </b-form-radio>
                  <b-form-radio value="pusing"> Pusing </b-form-radio>
                </div>
                <div class="d-flex mt-3 align-items-center">
                  <b-form-radio value="muntah"> Muntah </b-form-radio>
                  <b-form-radio value="lain-lain"> Lain-lain </b-form-radio>
                  <b-form-input
                    v-model="formData.reaksiSampingLain"
                    :disabled="formData.reaksiSamping !== 'lain-lain'"
                    placeholder="sebutkan"
                    class="ml-3"
                  />
                </div>
              </b-form-radio-group>
              <b-form-invalid-feedback
                :state="
                  v$.formData.reaksiSamping.$dirty
                    ? !v$.formData.reaksiSamping.$error
                    : null
                "
              >
                Data harus diisi
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-between">
        <div>
          <b-button
            variant="black"
            size="md"
            class="mr-2 mt-3"
            @click="$emit('back')"
          >
            <b-icon-arrow-left class="mr-2" /> Kembali
          </b-button>
        </div>
        <div>
          <b-button
            variant="primary"
            size="md"
            class="mt-3"
            @click="handleSubmit"
          >
            Lanjutkan <b-icon-arrow-right class="ml-2" />
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormRadio,
  BSpinner,
  BModal,
  BFormSelect,
  BCard,
  BFormRadioGroup,
  BFormTimepicker,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import OutgoingGoodsBloodBagAPI from "@/api/outgoinggoodsbloodbag/outgoingGoodsBloodBagAPI";
import ProgressBarStatus from "../../components/common/ProgressBarStatus.vue";
import manageHbAPI from "../../../../../../api/manageHB/manageHbAPI";
import managePendonorAPI from "../../../../../../api/managependonor/managePendonorAPI";
import manageAftafAPI from "../../../../../../api/manageAftaf/manageAftafAPI";

export default {
  name: "ViewDonationStatus",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BFormTextarea,
    BSpinner,
    BModal,
    BFormSelect,
    BCard,
    BFormRadioGroup,
    BFormTimepicker,
    BFormInvalidFeedback,
    ProgressBarStatus,
  },
  data() {
    return {
      labs: ["Registrasi", "Dokter", "HB", "Aftaf"],
      currentDataPendonor: null,
      showModal: false,
      acceptOrRejectionSuccess: false,
      routeName: "",
      routerPathname: "",
      hb_examination: "",
      modalType: "",
      isLoading: false,
      isBusy: false,
      formData: {
        noKantong: "",
        jamMulaiPenusukan: "",
        jamSelesaiPenusukan: "",
        antiseptic: "",
        reaksiPengambilan: "",
        reaksiPengambilanCC: "",
        reaksiSamping: "",
        reaksiSampingLain: "",
        rejectionReason: null,
        note: "",
      },
      rejectionReasons: [
        { value: null, text: "Pilih" },
        { value: "mislek", text: "Mislek" },
        { value: "tidak jadi", text: "Tidak Jadi" },
        { value: "lain-lain", text: "Lain-lain" },
      ],
      inputState: null,
      errorMessage: "",
      timeout: null,
    };
  },
  validations() {
    return {
      formData: {
        noKantong: { required },
        jamMulaiPenusukan: { required },
        jamSelesaiPenusukan: { required },
        antiseptic: { required },
        reaksiPengambilan: { required },
        reaksiSamping: { required },
      },
    };
  },

  computed: {
    isRejectionReasonSelected() {
      return this.rejectionReason !== null && this.rejectionReason !== "";
    },
    combineValidationStates() {
      if (this.v$.formData.noKantong.$dirty) {
        if (this.v$.formData.noKantong.$error) {
          return false;
        }
        return this.inputState;
      }
      return null;
    },
    showVuelidateError() {
      return (
        this.v$.formData.noKantong.$dirty && this.v$.formData.noKantong.$error
      );
    },
  },
  created() {
    this.initialize();
  },

  methods: {
    formatBloodType(bloodType) {
      if (bloodType.endsWith("-")) {
        return `${bloodType.slice(0, -1)} (Neg)`;
      }
      if (bloodType.endsWith("+")) {
        return `${bloodType.slice(0, -1)} (Pos)`;
      }
      return bloodType; // Jika tidak ada tanda + atau -
    },
    async initialize() {
      this.isBusy = true;
      await this.getHbDetail(this.$route.params.id);
      this.getPendonor();
      this.routerPathname = this.$route.matched[0].path;
    },
    async getHbDetail(id) {
      const response = await manageHbAPI.getDetail(id);
      this.hb_examination = response.data.data;
    },
    async getPendonor() {
      const response = await managePendonorAPI.getPendonor(
        this.hb_examination.registration_id
      );
      this.currentDataPendonor = response.data.data[0];
    },
    openConfirmationModal(type) {
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.$bvToast.toast("Formulir tidak valid. Harap periksa kembali.", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }
      this.modalType = type;
      this.showModal = true;
    },
    handleAction() {
      const uptake_reaction =
        this.formData.reaksiPengambilan === "stop"
          ? this.formData.reaksiPengambilanCC
          : this.formData.reaksiPengambilan;

      const sideEffect =
        this.formData.reaksiSamping === "lain-lain"
          ? this.formData.reaksiSampingLain
          : this.formData.reaksiSamping;

      const data = {
        hb_examinations_id: this.$route.params.id,
        blood_bag_number: this.formData.noKantong,
        venipuncture_start_time: this.formData.jamMulaiPenusukan,
        venipuncture_end_time: this.formData.jamSelesaiPenusukan,
        antiseptic_wipe: this.formData.antiseptic,
        uptake_reaction,
        side_effect: sideEffect,
        status: this.modalType === "accept" ? "Approved" : "Rejected",
        reason_rejected: this.formData.rejectionReason,
        note: this.formData.note,
      };
      manageAftafAPI.add(data);
      this.showModal = false;
      this.$bvToast.toast("Data Berhasil Ditambahkan", {
        title: "Success",
        variant: "success",
        solid: true,
      });
      this.$router.push({ name: "activasiAftafs" });
    },
    // CHECKING BARCODE PURPOSES
    onInput() {
      this.v$.formData.noKantong.$touch();
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.checkBarcode();
      }, 1000);
    },
    async checkBarcode() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      if (!this.formData.noKantong) {
        this.inputState = null;
        this.errorMessage = "";
        return;
      }

      this.isLoading = true;
      this.inputState = null;

      try {
        const response = await OutgoingGoodsBloodBagAPI.checkingBarcode({
          barcode: this.formData.noKantong,
        });

        if (response.data.success) {
          this.inputState = true;
        } else {
          this.inputState = false;
          this.errorMessage = response.data.message || "Barcode not found";
        }
      } catch (error) {
        this.inputState = false;
        this.errorMessage = error.response.data.message;
        console.error("Error checking barcode:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
