var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',[_c('progress-bar-status',{attrs:{"progress":3}})],1),_c('b-row',{staticClass:"bg-black-0 rounded pr-16 pr-sm-32 mx-0 d-flex column mt-3"},[_c('b-col',{staticClass:"pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden mb-10",attrs:{"cols":"12"}},[_c('h2',[_vm._v("Diisi oleh Aftaf")]),_c('h4',[_vm._v(" Golongan Darah: "),_c('strong',[_vm._v(" "+_vm._s(_vm.formatBloodType(_vm.hb_examination.blood_type)))])]),_c('b-form',{staticClass:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_vm._v(" No. Kantong "),_c('span',{staticClass:"required"})]),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',[_c('b-form-input',{attrs:{"id":"kantong","disabled":_vm.isLoading,"state":_vm.combineValidationStates},on:{"input":_vm.onInput,"blur":_vm.checkBarcode},model:{value:(_vm.formData.noKantong),callback:function ($$v) {_vm.$set(_vm.formData, "noKantong", $$v)},expression:"formData.noKantong"}}),(_vm.showVuelidateError)?_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(_vm.v$.formData.noKantong.$errors[0].$message)+" ")]):(_vm.inputState === false)?_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.inputState === true)?_c('b-form-valid-feedback',[_vm._v(" Barcode Siap Digunakan ")]):_vm._e(),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":"","label":"Checking barcode..."}}):_vm._e()],1)],1)],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"validasiKantongDarah"}},[_vm._v("Validasi Kantong Darah "),_c('span',{staticClass:"required"})])]),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-radio-group',{staticClass:"d-flex",attrs:{"state":_vm.v$.formData.validasiKantongDarah.$dirty
                  ? !_vm.v$.formData.validasiKantongDarah.$error
                  : null},model:{value:(_vm.formData.validasiKantongDarah),callback:function ($$v) {_vm.$set(_vm.formData, "validasiKantongDarah", $$v)},expression:"formData.validasiKantongDarah"}},[_c('b-form-radio',{attrs:{"value":"1"}},[_vm._v(" Valid ")]),_c('b-form-radio',{staticClass:"ml-3",attrs:{"value":"0"}},[_vm._v(" Tidak Valid ")])],1),_c('b-form-invalid-feedback',{staticClass:"text-danger",attrs:{"state":_vm.v$.formData.validasiKantongDarah.$dirty
                  ? !_vm.v$.formData.validasiKantongDarah.$error
                  : null}},[_vm._v(" Data harus diisi ")])],1)],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"cuciLenganLapTisu"}},[_vm._v("Cuci Lengan / Lap Tisu "),_c('br'),_vm._v("Antiseptic"),_c('span',{staticClass:"required"})])]),_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"jamPenusukan"}},[_vm._v("Jam Penusukan "),_c('span',{staticClass:"required"})])]),_c('b-col',{staticClass:"d-flex",staticStyle:{"gap":"1rem"},attrs:{"cols":"8"}},[_c('div',[_c('b-form-input',{attrs:{"type":"time","state":_vm.v$.formData.jamMulaiPenusukan.$dirty
                    ? !_vm.v$.formData.jamMulaiPenusukan.$error
                    : null,"placeholder":"Mulai","value":new Date().toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  })},model:{value:(_vm.formData.jamMulaiPenusukan),callback:function ($$v) {_vm.$set(_vm.formData, "jamMulaiPenusukan", $$v)},expression:"formData.jamMulaiPenusukan"}}),(
                  _vm.v$.formData.jamMulaiPenusukan.$dirty &&
                  _vm.v$.formData.jamMulaiPenusukan.$error
                )?_c('b-form-invalid-feedback',[_vm._v(" Data harus diisi ")]):_vm._e()],1),_c('span',{staticClass:"mt-2"},[_vm._v("s/d")]),_c('div',[_c('b-form-input',{attrs:{"type":"time","placeholder":"Selesai","state":_vm.v$.formData.jamSelesaiPenusukan.$dirty
                    ? !_vm.v$.formData.jamSelesaiPenusukan.$error
                    : null},model:{value:(_vm.formData.jamSelesaiPenusukan),callback:function ($$v) {_vm.$set(_vm.formData, "jamSelesaiPenusukan", $$v)},expression:"formData.jamSelesaiPenusukan"}}),(
                  _vm.v$.formData.jamSelesaiPenusukan.$dirty &&
                  _vm.v$.formData.jamSelesaiPenusukan.$error
                )?_c('b-form-invalid-feedback',[_vm._v(" Data harus diisi ")]):_vm._e()],1)])],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"cuciLenganLapTisu"}},[_vm._v("Cuci Lengan / Lap Tisu "),_c('br'),_vm._v("Antiseptic"),_c('span',{staticClass:"required"})])]),_c('b-col',{staticClass:"d-flex",attrs:{"cols":"8"}},[_c('b-form-radio-group',{staticClass:"d-flex",attrs:{"state":_vm.v$.formData.antiseptic.$dirty
                  ? !_vm.v$.formData.antiseptic.$error
                  : null},on:{"blur":function($event){return _vm.v$.formData.antiseptic.$touch()}},model:{value:(_vm.formData.antiseptic),callback:function ($$v) {_vm.$set(_vm.formData, "antiseptic", $$v)},expression:"formData.antiseptic"}},[_c('b-form-radio',{attrs:{"value":"1"}},[_vm._v(" Sudah ")]),_c('b-form-radio',{staticClass:"ml-3",attrs:{"value":"0"}},[_vm._v(" Belum ")])],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.v$.formData.antiseptic.$dirty
                  ? !_vm.v$.formData.antiseptic.$error
                  : null}},[_vm._v(" Data harus diisi ")])],1)],1),_c('h3',{staticClass:"mt-5 mb-3"},[_vm._v("Reaksi Pengambilan")]),_c('b-row',{staticClass:"reaksi d-flex align-items-center"},[_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v("Reaksi Pengambilan "),_c('span',{staticClass:"required"})])]),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-radio-group',{staticClass:"d-flex align-items-center",attrs:{"state":_vm.v$.formData.reaksiPengambilan.$dirty
                  ? !_vm.v$.formData.reaksiPengambilan.$error
                  : null},on:{"blur":function($event){return _vm.v$.formData.reaksiPengambilan.$touch()}},model:{value:(_vm.formData.reaksiPengambilan),callback:function ($$v) {_vm.$set(_vm.formData, "reaksiPengambilan", $$v)},expression:"formData.reaksiPengambilan"}},[_c('b-form-radio',{attrs:{"value":"lancar"}},[_vm._v(" Lancar ")]),_c('b-form-radio',{staticClass:"mx-3",attrs:{"value":"tidak lancar"}},[_vm._v("Tidak Lancar")]),_c('b-form-radio',{attrs:{"value":"stop"}},[_vm._v(" Stop ")]),_c('b-form-input',{staticStyle:{"width":"4rem","margin-left":"5px"},attrs:{"type":"number","disabled":_vm.formData.reaksiPengambilan !== 'stop',"placeholder":"cc"},model:{value:(_vm.formData.reaksiPengambilanCC),callback:function ($$v) {_vm.$set(_vm.formData, "reaksiPengambilanCC", $$v)},expression:"formData.reaksiPengambilanCC"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":_vm.v$.formData.reaksiPengambilan.$dirty
                  ? !_vm.v$.formData.reaksiPengambilan.$error
                  : null}},[_vm._v(" Data harus diisi ")])],1)],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"cols":"4"}},[_c('label',[_vm._v("Reaksi Samping "),_c('span',{staticClass:"required"})])]),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-radio-group',{attrs:{"state":_vm.v$.formData.reaksiSamping.$dirty
                  ? !_vm.v$.formData.reaksiSamping.$error
                  : null},on:{"blur":function($event){return _vm.v$.formData.reaksiSamping.$touch()}},model:{value:(_vm.formData.reaksiSamping),callback:function ($$v) {_vm.$set(_vm.formData, "reaksiSamping", $$v)},expression:"formData.reaksiSamping"}},[_c('div',{staticClass:"d-flex"},[_c('b-form-radio',{attrs:{"value":"tidak ada"}},[_vm._v(" Tidak Ada ")]),_c('b-form-radio',{attrs:{"value":"hematoma"}},[_vm._v(" Hematoma ")]),_c('b-form-radio',{attrs:{"value":"pusing"}},[_vm._v(" Pusing ")])],1),_c('div',{staticClass:"d-flex mt-3 align-items-center"},[_c('b-form-radio',{attrs:{"value":"muntah"}},[_vm._v(" Muntah ")]),_c('b-form-radio',{attrs:{"value":"lain-lain"}},[_vm._v(" Lain-lain ")]),_c('b-form-input',{staticClass:"ml-3",attrs:{"disabled":_vm.formData.reaksiSamping !== 'lain-lain',"placeholder":"sebutkan"},model:{value:(_vm.formData.reaksiSampingLain),callback:function ($$v) {_vm.$set(_vm.formData, "reaksiSampingLain", $$v)},expression:"formData.reaksiSampingLain"}})],1)]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.v$.formData.reaksiSamping.$dirty
                  ? !_vm.v$.formData.reaksiSamping.$error
                  : null}},[_vm._v(" Data harus diisi ")])],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('b-button',{staticClass:"mr-2 mt-3",attrs:{"variant":"black","size":"md"},on:{"click":function($event){return _vm.$emit('back')}}},[_c('b-icon-arrow-left',{staticClass:"mr-2"}),_vm._v(" Kembali ")],1)],1),_c('div',[_c('b-button',{staticClass:"mt-3",attrs:{"variant":"primary","size":"md"},on:{"click":_vm.handleSubmit}},[_vm._v(" Lanjutkan "),_c('b-icon-arrow-right',{staticClass:"ml-2"})],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }